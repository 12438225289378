export const GET_AUTH_TOKEN = 'auth/GET_AUTH_TOKEN';
export const GET_AUTH_TOKEN_SUCCESS = 'auth/GET_AUTH_TOKEN_SUCCESS';
export const GET_AUTH_TOKEN_FAILED = 'auth/GET_AUTH_TOKEN_FAILED';

export const DESTROY_AUTH_TOKEN = 'auth/DESTROY_AUTH_TOKEN';

export const REFRESH_TOKEN = 'auth/REFRESH_TOKEN';
export const REFRESH_TOKEN_SUCCESS = 'auth/REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAILED = 'auth/REFRESH_TOKEN_FAILED';
export const FORGET_PASSWORD = 'auth/FORGET_PASSWORD';
export const FORGET_PASSWORD_SUCCESS = 'auth/FORGET_PASSWORD_SUCCESS';
export const FORGET_PASSWORD_FAILED = 'auth/FORGET_PASSWORD_FAILED';
export const FORGET_PASSWORD_EMAIL = 'auth/FORGET_PASSWORD_EMAIL';
export const FORGET_PASSWORD_EMAIL_SUCCESS = 'auth/FORGET_PASSWORD_EMAIL_SUCCESS';
export const FORGET_PASSWORD_EMAIL_FAILED = 'auth/FORGET_PASSWORD_EMAIL_FAILED';